<template>
  <div class="innovations-content">
    <div class="main">
      <div class="main-left">
        <!-- 我的积分 -->
        <div class="left-top">
          <div class="integral">我的积分</div>
          <div class="top-main">
            <integral-list modelType="team" />
            <content-button :list="btns" />
          </div>
        </div>
        <!-- 看看大家的团队贡献 -->
        <div class="left-bottom">
          <div class="list-title">
            <div class="title">看看大家的{{ name }}提案</div>
            <div class="refresh" @click="nextPage">
              <i class="el-icon-refresh icon"><span class="icon-text">换一批</span></i>
            </div>
          </div>
          <!-- 提案列表 -->
          <proposal-list
            :data="proposalList"
            modelCode="team"
            :code="name"
            v-if="proposalList && proposalList.length > 0"
          />
          <tojoy-blank :blank-data="{ type: 'notdata' }" v-else />
        </div>
      </div>
      <!-- 历史分数 -->

      <div class="main-right">
        <div class="history-grade">历史分数</div>
        <tojoy-infinite-list :data="list" :func="getList" :total="historyTotal" :size="historySize">
          <template #item="{data}">
            <history-grade :data="data" />
          </template>
        </tojoy-infinite-list>
      </div>
    </div>
  </div>
</template>

<script>
import TojoyInfiniteList from '@/components/business/infinite-list'
import ProposalList from '@/components/initiate/proposal-list'
import HistoryGrade from '@/components/initiate/history-grade-list.vue'
import IntegralList from '@/components/initiate/integral-list.vue'
import { getInnovation, getScorehistorylist, getListCount } from '@/api/api_team'
import TojoyBlank from '@/components/business/blank/blank'
import {
  getCustomer,
  getScorehistorylist as getScorehistorylistCustomer,
  getListCount as getListCountCustomer
} from '@/api/api_customer'
import { btnData } from '@/components/initiate/state.js'
import contentButton from '../../components/initiate/content-button.vue'
export default {
  name: 'team',
  components: {
    ProposalList,
    IntegralList,
    HistoryGrade,
    TojoyInfiniteList,
    contentButton,
    TojoyBlank
  },
  data() {
    return {
      btnData: btnData,
      btns: [],
      code: '',
      name: '',
      list: [],
      page: 1,
      total: 0,
      size: 20,
      historySize: 20,
      historyTotal: 0,
      proposalList: []
    }
  },
  methods: {
    getCount() {
      this.handleApi('count')
        .then(res => {
          //我审批的待我审批条数 approvalPending
          //我发起的---待提交-toBeSubmitted 审批中-underReview
          let { toBeSubmitted, approvalPending } = res.data
          this.$set(this.btns[1], 'badge', toBeSubmitted)
          this.$set(this.btns[2], 'badge', approvalPending)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async handleApi(type = 'list', page) {
      //list 列表  count 列表条数接口
      try {
        if (sessionStorage.getItem('code') === 'contribute') {
          if (type === 'history') {
            let params = {
              page: page,
              size: this.historySize
            }
            return await getScorehistorylist(params)
          } else if (type === 'count') {
            let params = {
              contributeState: 0
            }
            return await getListCount(params)
          } else {
            let params = {
              page: this.page,
              size: this.size
            }
            return await getInnovation(params)
          }
        } else {
          if (type === 'history') {
            let params = {
              dimensionCode: sessionStorage.getItem('code'),
              page: page,
              size: this.historySize
            }
            return await getScorehistorylistCustomer(params)
          } else if (type === 'count') {
            let params = {
              customState: 1,
              dimensionCode: sessionStorage.getItem('code')
            }
            return await getListCountCustomer(params)
          } else {
            let params = {
              dimensionCode: sessionStorage.getItem('code'),
              page: this.page,
              size: this.size
            }
            return await getCustomer(params)
          }
        }
      } catch (err) {
        return err
      }
    },
    getList(page) {
      this.handleApi('history', page)
        .then(res => {
          let { total, list } = res.data
          if (list && list.length > 0) {
            this.list.push(...list)
            this.historyTotal = total
          }
        })
        .catch(err => console.log(err))
    },
    /**
     * 换一批
     */
    nextPage() {
      this.page = this.page + 1
      if (this.total < this.size) {
        this.$warning('暂无更多数据')
        return false
      }
      if (this.page > Math.floor(this.total / this.size) + 1) {
        this.$warning('暂无更多数据')
        return false
      }
      this.getInnovationList()
    },
    /**
     * 看看大家的创新提案
     */
    getInnovationList() {
      this.handleApi('list')
        .then(res => {
          let { list, total } = res.data
          this.total = total
          this.proposalList = list
        })
        .catch(err => {})
    },
    toPage(type, param) {
      //type 1发起提案 2我发起的 3我审批的
      let code = sessionStorage.getItem('code')
      if (param === 'add') {
        //发起提案
        let path =
          code === 'contribute'
            ? 'team-initiateProposal'
            : code === 'customize1'
            ? 'customer-initiateProposal'
            : 'customer2-initiateProposal'
        this.$router.push({
          name: path,
          query: {
            type: param
          }
        })
      } else {
        let path =
          code === 'contribute'
            ? 'team-initiate'
            : code === 'customize1'
            ? 'customer-initiate'
            : 'customer2-initiate'
        if (type === 3) {
          //我审批的
          path =
            code === 'contribute'
              ? 'team-approval'
              : code === 'customize1'
              ? 'customer-approval'
              : 'customer2-approval'
        }
        this.$router.push({
          name: path,
          query: {}
        })
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    to.meta.title = to.query.name ?? sessionStorage.getItem('name')
    next()
  },
  created() {
    this.code = sessionStorage.getItem('code')
    this.name = sessionStorage.getItem('name')
    this.getInnovationList()
    let btnObj = this.btnData.find(el => el.type === this.code)
    this.btns = btnObj.list
    this.getCount()
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/channel.scss';
</style>
